/* General styles for the launch header */
@import url('https://fonts.googleapis.com/css2?family=Bona+Nova+SC:ital,wght@0,400;0,700;1,400&display=swap');


.bona-nova-sc-regular {
    font-family: "Bona Nova SC", serif;
    font-weight: 400;
    font-style: normal;
  }
  
  .bona-nova-sc-bold {
    font-family: "Bona Nova SC", serif;
    font-weight: 700;
    font-style: normal;
  }
  
  .bona-nova-sc-regular-italic {
    font-family: "Bona Nova SC", serif;
    font-weight: 400;
    font-style: italic;
  }
  

html, body {
    overflow-x: hidden;
    width: 100%;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background-color: transparent;
}

/* Ensure all elements respect the full-width setting */
* {
    box-sizing: border-box;
}

.launch-header {
    background-color: #4e0771; /* Purple background */
    position: relative;
    width: 100%;
    padding: 0.5rem 0.6rem;
    z-index: 1;
}

.launch-header__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px; /* Adjust as needed */
    margin: 0 auto;
    padding: 0 1rem;
}

.launch-header__wrapper {
    display: flex;
    align-items: center;
    gap: 2rem;
}

.launch-header__title {
    font-family: "Montserrat", sans-serif;
    font-size: 2rem;
    font-weight: 900;
    text-transform: uppercase;
    color: #fff;
}

.launch-header__img {
    height: 3rem; /* Adjust as needed */
}

.launch-header__content {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.launch-header__info {
    display: flex;
    flex-direction: column; /* Stack text and buttons vertically */
    align-items: center;
    gap: 0.5rem;
}

.launch-header__text {
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-size: 1rem;
    font-weight: 600;
    color: #fff;
    white-space: nowrap;
}

.launch-header__btns {
    display: flex;
    align-items: center;
    gap: 0.6rem;
}

.launch-header__btn {
    display: flex;
    align-items: center;
    padding: 0.4rem 1rem;
    background-color: #fff;
    color: #000000;
    font-weight: 700;
    border-radius: 0.6rem;
    text-decoration: none;
    transition: background-color 0.3s, transform 0.3s; /* Smooth transition for background and size */
}

.launch-header__btn:hover {
    background-color: #f0f0f0;
    transform: scale(1.1);
}

.launch-header__btn-icon {
    margin-left: 0.5rem;
    fill: #9b57bd;
}



/* Media query to align the buttons and text in a row on larger screens */

@media (min-width: 640px) {
    .launch-header__info {
        flex-direction: row; /* Align text and buttons in a row */
        gap: 1rem;
    }
    .launch-header__text {
        font-size: 1.4rem;
    }
}

@media (min-width: 768px) {
    .launch-header__text {
        font-size: 2rem;
    }
    
}


@media (min-width: 920px) {
    .launch-header__text {
        padding-left: 4rem;
        padding-right: 4rem;
    }
    
}

@media (min-width: 1024px) {
    .launch-header__title {
        padding-left: 5rem;
        
    }
}

@media (min-width: 1280px) {
    .launch-header__title {
        padding-left: 7rem;
        
    }
}


/* Site Header Section */

.container {
    width: 100%; /* Adjust as needed */
    margin-left: auto;
    margin-right: auto;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

/* Site Header Styles */
.site-header {
    padding: 1rem 0;
    background-color: #000000;
}

.site-header__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
   
}

/* Logo styles */
.site-header__logo {
    display: flex;
    align-items: center;
}

.site-header__logo img {
    height: 2rem; /* Adjust logo height as needed */
}

/* Menu button styles */
.site-header__menu-btn {
    display: none; /* Hide menu button by default */
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
}

/* Menu icon styles */
.site-header__menu-btn img {
    height: 1.2rem; /* Adjust menu icon size as needed */
}

/* Menu items container */
.site-header__menu {
    display: flex;
    gap: 1rem;
    
}

.site-header__menu-item {
    list-style: none;
    position: relative;
     /* To position the dropdown */
}

.site-header__menu-item a {
    text-decoration: none;
    color: #fff; /* White text color */
    font-weight: 700;
}

/* Dropdown menu styles */
.menu-dropdown {
    display: none; /* Hide dropdown by default */
    position: absolute;
    top: 100%; /* Position directly below parent */
    left: 0;
    background-color: #1f0538; /* Deep purple background */
    width: 200%; /* Match width of parent */
    border-radius: 0.5rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Optional shadow for better visibility */
    padding: 0.5rem 0;
    
    
}

.menu-dropdown__ul {
    padding: 0;
    margin: 0;
    list-style: none;
   
}

.menu-dropdown__li {
    padding: 1rem 1rem;
    width: 150px;
}

.menu-dropdown__li a {
    text-decoration: none;
    color: #fff; /* White text color */
    font-weight: 400;
}

/* Styles for the --disable class */
.menu-dropdown__li.--disable {
    color: rgba(255, 255, 255, 0.6); /* More transparent */
    opacity: 0.7; /* Optional for blur effect */
    
}

.site-header__menu-item:hover .menu-dropdown {
    display: block; /* Show dropdown on hover */
}

.site-header__menu-item--dropdown {
    cursor: pointer; /* Show dropdown on hover */
   
}

/* Styles for the dropdown-status */
.dropdown-status {
    background-color: #4caf50; /* Green background */
    color: #fff; /* White text */
    border-radius: 0.25rem; /* Smaller border radius */
    padding: 0.05rem 0.25rem; /* Smaller padding */
    font-size: 0.25rem; /* Even smaller font size */
    display: inline-block;
    margin-left: 0.3rem; /* Reduced space between text and status */
}

.dropdown-icon {
    margin-left: 0.5rem; /* Space between text and icon */
    font-size: 0.8rem; /* Adjust size as needed */
    transition: transform 0.3s ease; /* Smooth rotation effect */
    color: white;
}

/* Rotate the icon when the dropdown is open */
.site-header__menu-item.is-active .dropdown-icon {
    transform: rotate(180deg);
}


/* Responsive styles */
@media (max-width: 768px) {
    /* Show menu button on small screens */
    .site-header__menu-btn {
        display: block;
    }

    /* Hide menu items by default on small screens */
    .site-header__menu {
        display: none;
        position: absolute;
        top: 4rem; /* Adjust top position based on menu button height */
        right: 1rem; /* Align to the right of the screen */
        background-color: #1f0538; /* Deep purple background */
        width: 150px; /* Adjust width as needed */
        border-radius: 0.5rem;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Optional shadow for better visibility */
        flex-direction: column;
        padding: 1rem;
        transition: max-height 0.3s ease; /* Smooth transition */
        max-height: 0; /* Initially hide menu */
        overflow: hidden;
        
       
    }

    /* Show menu items when the menu is open */
    .site-header__menu.is-open {
        display: flex;
        max-height: 500px;
         /* Adjust max-height as needed to accommodate menu items */
    }

    .site-header__menu-item {
        margin-bottom: 0.5rem;
        position: relative; /* Ensure dropdown positioning is relative to this item */
    }

    .site-header__menu-item--dropdown {
        cursor: pointer;
        margin-bottom: 3rem;/* Show dropdown on hover */
    }

    .menu-dropdown {
        display: inline-block; /* Hide dropdown by default */
        width: 100%; /* Full width of the menu item */
        background-color: #1f0538; /* Deep purple background */
        border-radius: 0.5rem;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Optional shadow for better visibility */
        padding: 0.3rem 0; /* Space between menu item and dropdown */
    }

    .site-header__menu-item.is-active .menu-dropdown {
        display: block; 
       /* Show dropdown when menu item is active */
    }
}

@media (min-width: 640px) {
   
}

@media (min-width: 768px) {
   
    
}


@media (min-width: 920px) {
   
    
}

@media (min-width: 1024px) {
   .site-header__menu-item {
    font-size: 1.5rem;
   }
}

@media (min-width: 1280px) {
    
}


/* Main Screen Section */

/* MainScreen.css */
.main-screen {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000000;
    color: #fff; /* Default text color */
    text-align: center; /* Center text alignment */
    padding: 10rem 1rem;
    z-index: -1; /* Add padding for spacing */ /* Set background to transparent */
   
  }

  .main-screen__wrapper {
    text-align: center;
    max-width: 1200px;
    margin: 0 auto;
    /* Center the background image */
}

.main-screen__title {
    font-family: 'bona-nova-sc-bold', sans-serif; /* Ensure you have this font loaded */
    font-size: 4rem;
    font-weight: bold;
    margin: 0;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7); /* 3D effect */
    transform: translateZ(0); /* For 3D effect */
    transition: transform 0.3s ease;
     /* Ensure title is above the background */
}

.main-screen__title.ui-title {
    color: #7b0786; /* Purple color */
}

.main-screen__title.ui-title2 {
    color: #fff; /* White color */
    margin-top: 1rem; /* Space between titles */
}

.main-screen__btns {
    margin-top: 2rem;
}

.main-screen__img {
    position: absolute;
    width: 225rem;
    height: 144rem;
    top: -25rem;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    z-index: -2;
   
}

.main-screen__wrapper {
    max-width: 51rem;
    margin: auto;
    text-align: center;
    justify-content: center;
    min-height: 40vh;
    padding-bottom: 16rem;
}

.main-screen__text {
    margin-top: 3rem
}

.main-screen__btns {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    grid-gap: 2rem;
    margin-top: 4rem
}

.main-screen__btns .ui-btn {
    width: 100%;
    max-width: 20rem
}

.main-screen .social-links {
    margin-top: 3rem
}

.social-links {
    margin-top: 2rem;
}

.circle3 {
    opacity: 0.2;
    transform-origin: center;
   
  }

  .circle1 {
    opacity: 0.2;
    transform-origin: center;
  }


  .circle2 {
    opacity: 0.2;
    transform-origin: center;
  }


  .star1 {
    opacity: 0.2;
    transform-origin: center;
    animation: blink 1s infinite;
  }

  .star2 {
    opacity: 0.2;
    transform-origin: center;
    animation: blink 1s infinite;
   
  }

  .star3 {
    opacity: 0.2;
    transform-origin: center;
    animation: blink 1s infinite;
  }

  .star4 {
    opacity: 0.2;
    transform-origin: center;
    animation: blink 1s infinite;
  }

  .star5 {
    opacity: 0.2;
    transform-origin: center;
    animation: blink 1s infinite;
  }

  .star6 {
    opacity: 0.2;
    transform-origin: center;
    animation: blink 1s infinite;
  }
  
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }


  @keyframes antispin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(-360deg); /* Negative value for counterclockwise rotation */
    }
  }

  @keyframes blink {
    0%, 100% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
  }

.ui-img-icon {
    display: inline-block;
    margin: 0 0.5rem;
}

.ui-img-icon__icon {
    width: 24px; /* Adjust as needed */
    height: 24px;
}

/* Media query for screens 768px and below */
@media (max-width: 768px) {
    .main-screen__title {
        font-size: 2rem; /* Adjust font size for smaller screens */
    }

    .main-screen__title.ui-title {
        font-size: 2.5rem; /* Adjust font size for "FLY HIGH" */
    }

    .main-screen__title.ui-title2 {
        font-size: 2.5rem; /* Adjust font size for "CONNECT FAST" */
    }

    .main-screen__wrapper {
        padding: 0 1rem; /* Add padding to the wrapper for small screens */
    }
}

.shooting-star {
    position: absolute;
    width: 5rem; /* Adjust size as needed */
    height: 0.1rem;
    background: linear-gradient(45deg, #445c85, rgba(255, 255, 255, 0));
    animation: shooting 2s linear infinite;
    z-index: -1;
}

.shooting-star2 {
    position: absolute;
    width: 5rem; /* Adjust size as needed */
    height: 0.1rem;
    background: linear-gradient(45deg, #965a46, rgba(255, 255, 255, 0));
    animation: shooting 2s linear infinite;
    z-index: -1;
}

.shooting-star3 {
    position: absolute;
    width: 5rem; /* Adjust size as needed */
    height: 0.1rem;
    background: linear-gradient(45deg, #f8f868, rgba(255, 255, 255, 0));
    animation: shooting 2s linear infinite;
    z-index: -1;
}

@keyframes shooting {
    0% {
        top: 0;
        left: 0;
        transform: rotate(45deg);
    }
    100% {
        top: 100vh; /* Adjust as needed */
        left: 100vw; /* Adjust as needed */
        transform: rotate(45deg);
    }
}

/* Additional shooting stars with different delays */
.shooting-star:nth-child(1) {
    animation-delay: 0s;
}
.shooting-star:nth-child(2) {
    animation-delay: 1s;
}
.shooting-star:nth-child(3) {
    animation-delay: 2s;
}
.shooting-star:nth-child(4) {
    animation-delay: 3s;
}
.shooting-star:nth-child(5) {
    animation-delay: 4s;
}
.shooting-star:nth-child(6) {
    animation-delay: 5s;
}


.shooting-star2:nth-child(1) {
    animation-delay: 0s;
}
.shooting-star2:nth-child(2) {
    animation-delay: 1s;
}
.shooting-star2:nth-child(3) {
    animation-delay: 2s;
}
.shooting-star2:nth-child(4) {
    animation-delay: 3s;
}
.shooting-star2:nth-child(5) {
    animation-delay: 4s;
}
.shooting-star2:nth-child(6) {
    animation-delay: 5s;
}

.ui-btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    color: #fff;
    background: #6d42c8;
    font-size: 1.4rem;
    padding: 1.4rem 3rem;
    border-radius: 1.2rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    will-change: transform;
    -webkit-transition: all .1s ease-in-out 0s;
    -o-transition: all .1s ease-in-out 0s;
    transition: all .1s ease-in-out 0s;
    white-space: nowrap
}

.ui-btn.--white {
    background: #fff;
    color: #171430;
    font-weight: 600
}

.ui-btn.--big {
    font-size: 1.6rem
}

.ui-btn.--small {
    font-size: 1.4rem;
    padding: 1rem 3rem
}

@media(max-width: 468px) {
    .ui-btn.--small {
        font-size:1rem
    }
}

.ui-btn.--border {
    background: rgba(0,0,0,0);
    border: .2rem solid #6d42c8
}

.ui-btn:disabled {
    background: #71717a;
    border-color: #71717a
}

.ui-btn.--disabled {
    opacity: .4
}

.ui-btn:not(.--disabled):hover {
    -webkit-transform:scale(1.05);
    -ms-transform: scale(1.05);
    transform: scale(1.05)
}

@media(max-width: 1200px) {
    .ui-btn {
        font-size:1.4rem;
        padding: 1.2rem 2.5rem
    }
}

@media(max-width: 576px) {
    .ui-btn {
        padding:1.2rem 1.5rem
    }
}

.ui-btn::before {
    width: 90%;
    height: 90%;
    position: absolute;
    top: 0;
    left: 0;
    border: .1rem solid #fff
}

.ui-btn.--fill {
    background: #6d42c8
}

.ui-btn.--m-auto {
    margin: auto
}

.ui-btn.--w100 {
    width: 100%
}

.ui-btn.--inline {
    display: inline-block
}

.ui-img-icon {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    border: .1rem solid #6d42c8;
    border-radius: 50%;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    position: relative; /* Ensure it is positioned */
    z-index: 10; /* Higher than other elements if needed */
}

.ui-img-icon.--disabled {
    opacity: .3
}

.ui-img-icon__wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 4rem;
    width: 4rem;
    padding: 1.2rem;
    border-radius: 50%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 2rem 2rem;
    overflow: hidden;
    cursor: pointer;
    will-change: transform
}

.ui-img-icon__icon {
    width: 1.8rem;
    height: 1.4rem
}

.ui-img-icon__icon--big {
    width: 2.4rem;
    height: 2rem
}

.ui-img-icon.--big .ui-img-icon__wrapper {
    padding: 1.4rem;
    width: 6rem;
    height: 6rem
}

.ui-img-icon.--fill {
    background-color: #6d42c8
}

.ui-img-icon.--btn:not(.ui-img-icon.--btn.--disabled):hover {
    -webkit-transform:scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
    background-color: #6d42c8
}

/* MainScreen.css */
  
  .shining-stars {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: -1; /* Adjust if needed */
  }
  
  .shining-star {
    position: absolute;
    width: 10px; /* Size of the star */
    height: 10px;
    background: linear-gradient(to right, #8c52ff, #5ce1e6); /* Color of the star */
    border-radius: 50%;
    box-shadow: 0 0 5px rgba(255, 255, 255, 0.8); /* Glowing effect */
    animation: shine 3s infinite alternate;
    z-index: -1;
  }
  
  @keyframes shine {
    from {
      opacity: 0.1;
    }
    to {
      opacity: 1;
    }
  }
  
  /* Positioning stars at random locations and angles */
  .shining-star:nth-child(1) { top: 10%; left: 20%; transform: rotate(15deg);  }
  .shining-star:nth-child(2) { top: 25%; left: 70%; transform: rotate(-10deg); }
  .shining-star:nth-child(3) { top: 40%; left: 70%; transform: rotate(5deg); }
  .shining-star:nth-child(5) { top: 75%; left: 80%; transform: rotate(25deg); }
  .shining-star:nth-child(6) { top: 90%; left: 40%; transform: rotate(-5deg); }
  .shining-star:nth-child(7) { top: 20%; left: 80%; transform: rotate(30deg); }
  .shining-star:nth-child(10) { top: 80%; left: 10%; transform: rotate(-25deg); }
  .shining-star:nth-child(11) { top: 70%; left: 10%; transform: rotate(-25deg); }
  

/* Base styles */
.products {
    background-color: black;
    padding: 20px;
}

.products__title,
.products__text,
.product-item__title,
.product-item__text,
.products-text__text,
.products-text__tip {
    color: white;
}

.products__title {
    font-size: 2rem;
    margin: 20px 0;
}

.products__text {
    font-size: 1.2rem;
    margin: 20px 0;
}

.product-item {
    padding: 20px;
    margin: 20px 0;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background: #0d1026;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.product-item__title {
    font-size: 1.5rem;
    margin: 10px 0;
}

.product-item__text {
    font-size: 1rem;
    margin: 10px 0;
}

.product-item__btn {
    display: inline-block;
    padding: 10px 20px;
    margin-top: 10px;
    background-color: #6909a5;
    color: white;
    text-decoration: none;
    border-radius: 5px;
}

.product-item__btn:hover {
    background-color: #777;
}

.products-text__text,
.products-text__tip {
    margin: 20px 0;
}

.products-text__tip a {
    color: #00f;
    text-decoration: underline;
}

.products__net-background {
    position: relative;
    text-align: center;
}

.products__net-background::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url('../img/Net.svg') no-repeat center center;
    background-size: cover;
}

.loader {
    width: 4rem;
    height: auto;
    display: block;
    margin: 10px auto;
}

.products__fire-background {
    position: relative;
}

@keyframes runAcrossScreen {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(100vw);
    }
}

.runnerp {
    width: 20rem;
    display: block;
    margin: 20px auto;
    position: relative;
    animation: runAcrossScreen 3s linear infinite;
}

.h120 {
    height: 120px;
}

/* Responsive styles */
@media (max-width: 1536px) {
    .products {
        padding: 10px;
    }

    .products__title,
    .products__text,
    .product-item__title,
    .product-item__text,
    .products-text__text,
    .products-text__tip {
        text-align: left;
    }

    .products__title,
    .products__text {
        position: relative;
        z-index: 1;
    }

    .products__title {
        color: #7b038b;
        font-style: italic;
    }

    .products__text {
        margin-top: -5rem;
        font-size: medium;
    }

    .products__net-background::before {
        background-size: cover;
    }

    .loader {
        width: 20rem;
        margin-top: -5rem;
        margin-left: -7rem;
    }

    .products__items {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0;
    }

    .product-item {
        padding: 1rem;
        margin: 0.5rem 0;
        width: 100%;
        max-width: 100%;
        box-sizing: border-box;
    }

    .product-item__img {
        max-width: 100%;
        height: auto;
    }

    .product-item__title {
        font-size: 1.5rem;
    }

    .product-item__text {
        font-size: 1rem;
    }

    .product-item__btn {
        padding: 10px;
        font-size: 0.9rem;
    }

    .product-item__top-icon {
        left: -1.5rem;
        top: -1.5rem;
        width: 3rem;
        height: 3rem;
    }
}

/* Layout for larger screens */
.products__items {
    margin-top: 8rem;
    display: grid;
    grid-template-columns: 1fr;
    align-items: flex-start;
    grid-gap: 2rem;
}

@media (min-width: 768px) {
    .products__items {
        grid-template-columns: 1fr 1fr;
    }

    .products__items .product-item:nth-child(3) {
        grid-column: 1 / -1;
        justify-self: center;
    }
}

@media (min-width: 980px) {
    .products__items {
        grid-template-columns: 1fr 1fr 1fr;
    }

    .products__items .product-item:nth-child(3) {
        grid-column: auto;
    }
}

@media (min-width: 1200px) {
    .products__items {
        grid-template-columns: 1fr 1fr 1fr;
    }

    .products__items .product-item:nth-child(3) {
        grid-column: auto;
    }
}

.product-item__img {
    display: block;
    margin: 0 auto;
    width: 100%;
    max-width: 14rem;
    height: 14rem;
    object-fit: contain;
}

.product-item__title {
    font-size: 2rem;
    margin-top: 1rem;
    text-align: center;
}

@media (max-width: 1200px) {
    .product-item__title {
        font-size: 2rem;
    }
}

.product-item__text {
    text-align: center;
    font-size: 1rem;
    margin-top: 2rem;
}

.product-item__btn {
    margin-top: 4rem;
    z-index: 1;
}

.product-item__top-icon {
    position: absolute;
    left: -2.4rem;
    top: -2.4rem;
    width: 5rem;
    height: 5rem;
}

.product-item__top-icon svg {
    width: 100%;
    height: 100%;
}


/* Base styles */
.roadmap {
    background-color: #000000;
    color: white;
    max-width: 1200;
    padding-bottom: 171rem;
}

@media(max-width: 5000px) {
    .roadmap {
        padding-bottom: 0;
    }
}

.roadmap__title {
    text-align: left;
    color: #6d036d;
    font-size: 5rem;
    user-select: none;
    text-shadow: #fcfafa 3px 0px 0px, #31225d 2.83487px .981584px 0px, #31225d 2.35766px 1.85511px 0px, #31225d 1.62091px 2.52441px 0px, #31225d .705713px 2.91581px 0px, #31225d -0.287171px 2.98622px 0px, #31225d -1.24844px 2.72789px 0px, #31225d -2.07227px 2.16926px 0px, #31225d -2.66798px 1.37182px 0px, #31225d -2.96998px .42336px 0px, #31225d -2.94502px -0.571704px 0px, #31225d -2.59586px -1.50383px 0px, #31225d -1.96093px -2.27041px 0px, #31225d -1.11013px -2.78704px 0px, #31225d -0.137119px -2.99686px 0px, #31225d .850987px -2.87677px 0px, #31225d 1.74541px -2.43999px 0px, #31225d 2.44769px -1.73459px 0px, #31225d 2.88051px -0.838247px 0px, #6d42c8 0px 4px 8px;
}

@media(max-width: 5000px) {
    .roadmap__title {
        text-align: left;
    }
}

.roadmap__img {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 150rem;
    height: 140rem;
}

@media(max-width: 5000px) {
    .roadmap__img {
        position: relative;
        left: unset;
        transform: none;
        height: auto;
        width: auto;
        margin-top: 2rem;
    }
}

@media(max-width: 5000px) {
    .roadmap__img svg {
        display: none;
    }
}

.roadmap__wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.roadmap-phase {
    position: relative;
    width: 100%;
    max-width: 33%;
    box-sizing: border-box;
}

@media(max-width: 5000px) {
    .roadmap-phase {
        max-width: 50%;
    }

    .roadmap-phase:nth-child(3) {
        max-width: 100%;
    }
}

@media(max-width: 768px) {
    .roadmap-phase {
        max-width: 100%;
    }
}

.roadmap-phase__title {
    font-size: 2rem;
}

@media(max-width: 5000px) {
    .roadmap-phase__text {
        margin-top: 2rem;
    }
}

.roadmap-phase__img {
    width: 23.8rem;
    height: 23.8rem;
}

@media(max-width: 5000px) {
    .roadmap-phase__img {
        width: 16rem;
        height: 16rem;
    }
}

.roadmap-phase .ui-format ul {
    margin-bottom: -0.4rem;
}

.roadmap-phase .ui-format ul li {
    margin-bottom: 0.4rem;
}



.partners {
    position: relative;
    padding: 20px;
    text-align: center;
    background: black; /* Assuming you want a black background */
    color: white;
  }
  
  .partners .container {
    position: relative;
    z-index: 1;
  }
  
  .partners__img {
    position: relative;
    width: 25rem; /* Adjust size as needed */
    height: auto;
  }
  
  .partners__img:first-of-type {
    top: 0;
    left: 0;
  }
  
  .partners__img:last-of-type {
    bottom: 0;
    right: 0;
  }
  
  .partners__text {
    margin: 0;
  }

 /* General styles for the footer */
.site-footer {
    background-color: black;
    padding: 20px 0;
    color: #ffffff;
}

.site-footer .container {
    max-width: 1200px;
    margin-bottom: 5rem;
    padding: 0 15px;
}

.site-footer__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
}

.site-footer__side {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}

.site-footer__media {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.site-footer__logo {
    margin-right: 25px;
}

.site-footer__logo img {
    max-width: 6rem; /* Decrease the logo size */
}

.site-footer__text {
    font-size: 1.2rem; /* Adjust as needed */
}







.site-footer__menu-wrapper {
    display: flex;
    flex-direction: row; /* Change to row to arrange columns horizontally */
    flex-wrap: wrap; /* Wrap items */
    gap: 20px; /* Add some gap between the columns */
}

.site-footer__col-3 {
    display: flex;
    flex-direction: column; /* Column direction for individual sections */
    width: calc(33.33% - 20px); /* Adjust width to fit in three columns */
}

.site-footer__title {
    font-size: 1.2rem;
    margin: 20px 10px;
    color: #3a025d;
}

.site-footer__ul {
    list-style-type: none;
    padding: 0;
    
    margin: 0;
    display: flex;
    flex-direction: row; /* Change to row to arrange items horizontally */
    flex-wrap: wrap; /* Wrap items */
    gap: 10px; /* Add some gap between the items */
}

.site-footer__li {
    margin-bottom: 5px;
    padding-right: 20px;
    padding-left: 5px;
}

.site-footer__li a {
    color:#5e5f61;
    text-decoration: none;
}

.site-footer__li a:hover {
    text-decoration: underline;
}

.site-footer__policy {
    text-align: left;
    margin-top: 20px;
    font-size: 0.675rem;
    color:#5e5f61;
}

.site-footer__divider {
    width: 100%;
    height: 2px; /* Adjust the height of the line as needed */
    background-color: rgb(55, 2, 55); /* Set the color to purple */
    margin-top: 20px; /* Add some space above the line */
    margin-bottom: 10px; /* Add some space below the line */
}


/* Mobile styles */
@media (max-width: 768px) {
    .site-footer__wrapper {
        flex-direction: column;
        align-items: flex-start;
    }

    .site-footer__media {
        flex-direction: column;
        align-items: flex-start;
        text-align: left;
    }

    .site-footer__text {
        margin-left: 0;
        margin-top: 10px;
    }

    .social-links {
        justify-content: flex-start;
        width: 100%;
        margin-top: 10px;
    }

    .site-footer__menu-wrapper {
        flex-direction: column; /* Stack the menu sections vertically */
        width: 100%;
    }

    .site-footer__col-3 {
        width: 100%;
    }

    .site-footer__ul {
        flex-direction: row;
         /* Stack the list items vertically */
    }

    .site-footer__li a {
        font-size: small;
        text-decoration: none;
    }
}
